import {useEffect, useState} from 'react';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import SelectCombo from '@common/selectcombo';
import Chip from '@common/Chip';
import {toLower} from 'lodash';
import {saveFilter} from '../../../../actions';

function Founded(props) {
  const [selectedValues, setSelectedValues] = useState(
    props.savedFilters.foundedyear,
  );

  const [curMinSelectedValue, setCurMinSelectedValue] = useState(undefined);
  const [curMaxSelectedValue, setCurMaxSelectedValue] = useState(undefined);

  const [minIndex, setMinIndex] = useState(-1);

  const [minHasError, setMinHasError] = useState(false);
  const [maxHasError, setMaxHasError] = useState(false);

  const years = () => {
    const today = new Date();
    let year = today.getFullYear();
    let allYears = [];
    for (let i = 0; i < 125; i++) {
      allYears.push({title: `${year - i}`, type: `${year - i}`});
    }
    return allYears;
  };

  const maxYears = (min = -1) => {
    const allYears = years().slice(0, min === -1 ? 1 : min + 1);
    return allYears;
  };

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['foundedyear'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    updateFilterReducer(
      selectedValues.map(val => {
        if (val) {
          const parts = val.split('-');
          return `${parts[0]}-${parts[1]}`;
        }
        return '';
      }),
    );
  }, [selectedValues]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;

    if (
      filterObject['foundedyear'].length === 0 &&
      selectedValues.length !== 0
    ) {
      setSelectedValues([]);
      setMaxHasError(false);
      setMinHasError(false);
      setCurMinSelectedValue(undefined);
      setCurMaxSelectedValue(undefined);
    }
  }, [props.savedFilters]);

  return (
    <div
      className={`relative ${
        props.savedFilters['foundedyear'].length > 0 ? 'h-auto' : 'h-20'
      }`}>
      {props.savedFilters['foundedyear'].length > 0 ? (
        <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
          <div className="justify-start items-center gap-1.5 flex flex-wrap">
            {props.savedFilters['foundedyear']
              .map(origVal => ({title: origVal, action: 'include'}))
              .map((val, index) => (
                <Chip
                  key={`${index}`}
                  {...val}
                  onRemove={() => {
                    let filters = props.savedFilters;
                    let filterObject = filters;
                    const filtered = props.savedFilters['foundedyear'].filter(
                      item => toLower(item) !== toLower(val.title),
                    );
                    filterObject['foundedyear'] = filtered;
                    filters = {...filters, ...filterObject};
                    props.saveFilter(props.session, filters, () => {});
                  }}
                />
              ))}
          </div>
        </div>
      ) : null}
      <fieldset>
        <legend className="text-xs leading-6 text-gray-900 pt-4">
          {LocaleStrings.search.companysearch.founded_placeholder}
        </legend>
        <div className="flex items-center gap-1">
          <div className="w-2/5 z-50">
            <SelectCombo
              values={years()}
              placeholder="Year"
              onSelectValue={val => {
                const selectedIndex = years().findIndex(
                  year => year.title === val.title,
                );
                setMinIndex(selectedIndex);
                setCurMinSelectedValue(val);
                setMinHasError(false);
              }}
              value={curMinSelectedValue}
              hasError={minHasError}
            />
          </div>
          <div className="text-xs text-gray-500">to</div>
          <div className="w-2/5 z-50">
            <SelectCombo
              values={maxYears(minIndex)}
              placeholder="Year"
              onSelectValue={val => {
                setCurMaxSelectedValue(val);
                setMaxHasError(false);
              }}
              value={curMaxSelectedValue}
              hasError={maxHasError}
            />
          </div>
          <div className="items-center flex">
            <button
              className="text-primary text-xs ml-2"
              onClick={() => {
                if (!curMinSelectedValue) {
                  setMinHasError(true);
                } else if (!curMaxSelectedValue) {
                  setMaxHasError(true);
                } else if (
                  curMinSelectedValue.type > curMaxSelectedValue.type
                ) {
                  setMinHasError(true);
                } else {
                  let newArray = [...selectedValues];
                  newArray.push(
                    `${curMinSelectedValue.type}-${curMaxSelectedValue.type}`,
                  );
                  setSelectedValues(newArray);
                  setCurMaxSelectedValue(undefined);
                  setCurMinSelectedValue(undefined);
                }
                /**
                 * else if (
                  curMinSelectedValue.type === curMaxSelectedValue.type
                ) {
                  setMinHasError(true);
                  setMaxHasError(true);
                }
                 */
              }}>
              {LocaleStrings.search.apply}
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    seniorities: state.preloadedPeopleFilterSupportData.seniorities,
  };
}

export default connect(mapStateToProps, {saveFilter})(Founded);
