import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PeopleTable from './PeopleTable';
import PeopleFavsTable from './PeopleFavsTable';
import CompanyTable from '../companyresults/CompanyTable';
import CompanyFavsTable from '../companyresults/CompanyFavsTable';
import {useParams} from 'react-router-dom';
import {onSearchSubMenuSelected} from '../../sidebar/actions';
import {decode} from 'js-base64';
import {saveFilter} from '../actions';
import ContactsTable from '../../revealedcontacts/contacts/ContactsTable';
import CrmPopup from '../../../dashboard/revealedcontacts/contacts/crmpopup';
import {fetchCRMDetails} from '../actions';
import {fetchLabels} from '../../mylist/actions';
import Favourites from '../favourites';
import {classNames} from '@commonFunction';
import MyListHome from '@mainMyList';

import LinkedinSearch from '../linkedinsearch/components';

function SearchResults(props) {
  const [hasQuery, setHasQuery] = useState(false);

  const params = useParams();

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let searchObject = filters;

    searchObject['companyname'] = [vals];
    filters = {...filters, ...searchObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    props.fetchLabels(props.session, {action: 'availablelabels'}, _ => {});
  }, [props.availablelabels]);

  useEffect(() => {
    props.fetchCRMDetails(props.session, resp => {});
  }, []);

  const renderComponents = calculatedHeight => {
    if (props.searchSelectedSubMenu === 'company') {
      return (
        <CompanyTable
          calculatedHeight={calculatedHeight}
          headcount={props.headcount}
        />
      );
    } else if (props.searchSelectedSubMenu === 'favs') {
      return <Favourites />;
    } else if (props.searchSelectedSubMenu === 'people') {
      return (
        <PeopleTable
          calculatedHeight={calculatedHeight}
          headcount={props.headcount}
        />
      );
    } else if (props.searchSelectedSubMenu === 'revealedcontacts') {
      return (
        <ContactsTable
          calculatedHeight={calculatedHeight}
          headcount={props.headcount}
        />
      );
    } else if (props.searchSelectedSubMenu === 'mylists') {
      return <MyListHome />;
    } else if (props.searchSelectedSubMenu === 'linkedinsearch') {
      return <LinkedinSearch {...props} />;
    }

    return null;
  };

  // useEffect(() => {
  //   if (params.query && !hasQuery) {
  //     const query = params.query;
  //     if (query.indexOf('view-') !== -1 && !hasQuery) {
  //       const data = query.replace('view-', '');
  //       const decode1 = decode(data);
  //       const final = JSON.parse(decode(decode1));
  //       updateFilterReducer(final);
  //       setTimeout(() => {
  //         setHasQuery(true);
  //       }, 1000);

  //       setTimeout(() => {
  //         props.onSearchSubMenuSelected('people');
  //       }, 2000);
  //     }
  //   }
  // }, [hasQuery]);

  const calculatedHeight = 0;
  const hideFilters =
    props.searchSelectedSubMenu === 'favs' ||
    props.searchSelectedSubMenu === 'mylists';
  return (
    <>
      <div
        className={classNames(
          'overflow-hidden mx-2 bg-white content-center my-2',
          !hideFilters ? 'shadow-md shadow-gray-300 rounded-xl border' : '',
        )}>
        {renderComponents(calculatedHeight)}
      </div>
      {props.iscrmModuleModalOpen && props.iscrmModuleModalOpen.isOpen ? (
        <CrmPopup />
      ) : (
        ''
      )}
    </>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    availablelabels: state.availablelabels,
    iscrmModuleModalOpen: state.iscrmModuleModalOpen,
  };
}

export default connect(mapStateToProps, {
  onSearchSubMenuSelected,
  saveFilter,
  fetchLabels,
  fetchCRMDetails,
})(SearchResults);
