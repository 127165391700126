import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {classNames} from '@commonFunction';
import IconArrowLeft from './cells/icons/arrowleft';
import IconArrowRight from './cells/icons/arrowright';
import ListTable from './ListTable';
import CardTable from './CardTable';
import PaginationBar from './cells/components/paginationbar';
import {
  FILTER_TYPE_PEOPLE,
  filterPeople,
  saveFilter,
  peopleSearchEmpty,
  cancelApiRequest,
} from '../actions';
import SkeletonLoader from '../../../../common/skeletonloader';
import NoResult from '../../../../common/noresult';
import LocaleStrings from '../../../languages';
import Revealallpopup from './components/revealallpopup';
import RevealMenu from '../../../../common/revealmenu';
import {RevealMenuTypes} from '../../../../common/revealmenu/menudropdown';
import {useParams} from 'react-router-dom';
import {decode} from 'js-base64';
import IconToolTip from '../../../../common/icontooltip';
import {fetchAppuser} from '@sidebarActions';
import {
  revealContact,
  revealAllContact,
} from '../../../dashboard/search/actions/';
import ProcessingIndicator from '../../../../common/processingindicator';

const items = [
  {name: 'List 1', href: '#'},
  {name: 'List 2', href: '#'},
];

function PeopleTable(props) {
  const counterRef = useRef(0);
  const maxRowPerPage = props.appuserData?.settings?.max_selection ?? 25;
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [processingReveal, setProcessingReveal] = useState(false);
  const [bulkRevealInitiated, setBulkRevealInitiated] = useState(false);
  const [bulkRevealType, setBulkRevealType] = useState();

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [cardView, setCardView] = useState(false);

  const [showList, setShowList] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [toggleRevealAllPopup, setToggleRevealAllPopup] = useState(false);

  const params = useParams();

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;

    const modifiedParams = isPaginating
      ? {
          ...props.savedFilters,
          paginating: isPaginating,
          totalcount: props.totalcount,
          numPages: props.numPages,
          headcounts: props.headcount,
        }
      : {
          ...props.savedFilters,
          paginating: isPaginating,
        };
    props.filterPeople(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      modifiedParams,
      FILTER_TYPE_PEOPLE,
      props.appuserData,
      _ => {
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
          }, 200);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }
      },
    );
  };

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let searchObject = filters;

    searchObject['companyname'] = [vals];
    filters = {...filters, ...searchObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    if (params.query) {
      const query = params.query;
      if (query.indexOf('view-') !== -1) {
        const data = query.replace('view-', '');
        const decode1 = decode(data);
        const final = JSON.parse(decode(decode1));
        setIsLoading(true);
        props.fetchAppuser(props.session, props.user.appuserid, _ => {
          updateFilterReducer(final);
        });
      }
    }
  }, [params.query]);

  useEffect(() => {
    if (!isLoading && isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading && isRefreshing) {
      applyFilter();
    }
  }, [isRefreshing]);

  useEffect(() => {
    if (!props.processingSideFilterToggle && !params.query) {
      setIsLoading(true);
      setFirstLoad(true);

      if (props.savedFilters.hasOwnProperty('hasFilters')) {
        applyFilter(true);
      } else {
        props.peopleSearchEmpty(FILTER_TYPE_PEOPLE);
        props.cancelApiRequest(FILTER_TYPE_PEOPLE);
        setCurPage(1);
        setTimeout(() => {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }, 200);
      }
    } else if (params.query && firstLoad && props.appuserData.credits) {
      applyFilter(false);
    }
  }, [props.savedFilters]);

  useLayoutEffect(() => {
    const selectableData = props.showTechnologyBlock
      ? props.filteredPeople.slice(0, 5)
      : props.filteredPeople;
    if (selectableData && selectableData.length > 0) {
      const isIndeterminate =
        selectedPersons.length > 0 &&
        selectedPersons.length < selectableData.length;
      setChecked(selectedPersons.length === selectableData.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedPersons, props.filteredPeople]);

  function toggleAll() {
    const selectableData = props.showTechnologyBlock
      ? props.filteredPeople.slice(0, 5)
      : props.filteredPeople;
    setSelectedPersons(checked || indeterminate ? [] : selectableData);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    if (props.totalcount > 0 && !props.showTechnologyBlock)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} contacts`;

    return '0 contacts';
  };

  const revealAll = async type => {
    if (selectedPersons.length > 0) {
      // setProcessingReveal(true);

      setBulkRevealInitiated(true);
      setBulkRevealType(type);

      // const revealPromises = selectedPersons.map(
      //   person =>
      //     new Promise(resolve => {
      //       props.revealAllContact(
      //         props.session,
      //         {peopleid: person.peopleid},
      //         resp => resolve(),
      //       );
      //     }),
      // );

      // // Wait for all promises to resolve
      // await Promise.all(revealPromises);

      // setSelectedPersons([]);
      // setChecked(false);
      // setIndeterminate(false);

      // setTimeout(() => {
      //   setProcessingReveal(false);
      //   setIsLoading(true);
      //   applyFilter(false);
      // }, 300);
    }
  };

  const onRevealContact = async type => {
    if (selectedPersons.length > 0) {
      // setBulkRevealInitiated(true);
      // setBulkRevealType(type);

      setProcessingReveal(true);

      const revealPromises = selectedPersons.map(
        person =>
          new Promise(resolve => {
            props.revealContact(
              props.session,
              {peopleid: person.peopleid, type: type, action: 'reveal'},
              res => resolve(), // Resolve the promise in the callback
            );
          }),
      );

      // Wait for all promises to resolve
      await Promise.all(revealPromises);

      setSelectedPersons([]);
      setChecked(false);
      setIndeterminate(false);

      setTimeout(() => {
        setProcessingReveal(false);
        setIsLoading(true);
        applyFilter(false);
      }, 300);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-4 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 -my-2 mb-4 relative items-center grow shrink basis-0 self-stretch">
              {!processingReveal ? (
                <div>
                  <input
                    type="checkbox"
                    className="rounded border-gray-500 text-primary focus:ring-primary hover:cursor-pointer hover:border-primary hover:border-2 hover:bg-gray-100"
                    ref={checkbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </div>
              ) : null}
              {!processingReveal && selectedPersons.length == 0 ? (
                <div className="ml-2 mt-1 text-sm font-semibold">
                  {renderResultText()}
                </div>
              ) : null}
              {selectedPersons.length > 0 ? (
                !processingReveal ? (
                  <div className="flex items-center gap-5">
                    <div className="ml-2 mt-1 text-sm font-semibold">
                      {`${selectedPersons.length} selected`}
                    </div>
                    <div className="">
                      <RevealMenu
                        onSelectedMenu={menuType => {
                          if (menuType === RevealMenuTypes.ALL) {
                            revealAll('all');
                          } else {
                            onRevealContact(menuType);
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      'transition ease-in duration-300 absolute left-5 -top-2 flex h-12 items-center space-x-3 bg-white sm:left-5',
                    )}>
                    <ProcessingIndicator message={'Processing Reveal'} />
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            <div className="flex -mx-4 -my-5 mb-2 relative gap-6 items-center">
              <div
                className={classNames(
                  'w-22 h-8 rounded-lg shadow border border-gray-300 justify-start items-start inline-flex overflow-hidden',
                  props.showTechnologyBlock ? 'opacity-50' : 'opacity-100',
                )}>
                <div
                  className="px-3 py-2 bg-white border-r border-gray-300 justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (curPage > 1 && !props.showTechnologyBlock) {
                      setCurPage(curPage - 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div className="w-5 h-4 relative rounded-lg">
                    <IconArrowLeft />
                  </div>
                </div>
                <div
                  className="px-3 py-2 bg-white justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (
                      curPage < props.numPages &&
                      !props.showTechnologyBlock
                    ) {
                      setCurPage(curPage + 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div className="w-5 h-4 relative rounded-lg">
                    <IconArrowRight />
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <IconToolTip
                  type="cardview"
                  onSelect={() => {
                    setCardView(true);
                  }}
                  selected={cardView}
                />
                <IconToolTip
                  type="listview"
                  onSelect={() => {
                    setCardView(false);
                  }}
                  selected={!cardView}
                />
              </div>
            </div>
          </div>
          {props.filteredPeople ? (
            <div
              className={classNames(
                '-my-2 overflow-x-auto -ml-16 overflow-visible',
                cardView ? '-mr-16' : '-mr-20',
              )}>
              <div
                className="inline-block min-w-full py-2 align-middle pl-8 pr-4"
                style={{height: 'calc(100vh / var(--zoom) - 240px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : cardView ? (
                  <CardTable
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_PEOPLE}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(p => p !== person),
                      );
                    }}
                    onRefresh={() => {
                      setIsRefreshing(true);
                    }}
                    applyFilter={applyFilter}
                  />
                ) : (
                  <ListTable
                    bulkRevealInitiated={bulkRevealInitiated}
                    bulkRevealType={bulkRevealType}
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_PEOPLE}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(p => p !== person),
                      );
                    }}
                    onRefresh={() => {
                      setIsRefreshing(true);
                    }}
                    applyFilter={applyFilter}
                    onRevealCompletion={person => {
                      counterRef.current += 1; // Updates the variable

                      if (counterRef.current === selectedPersons.length) {
                        setSelectedPersons([]);
                        setChecked(false);
                        setIndeterminate(false);
                        setBulkRevealInitiated(false);
                        counterRef.current = 0;
                      }
                    }}
                  />
                )}
              </div>
              <Revealallpopup
                open={toggleRevealAllPopup}
                selectedPersons={selectedPersons}
                onCloseModal={() => {
                  setToggleRevealAllPopup(false);
                }}
                onCompletion={() => {
                  setToggleRevealAllPopup(false);

                  setSelectedPersons([]);
                  setChecked(false);
                  setIndeterminate(false);

                  setTimeout(() => {
                    setIsLoading(true);
                    applyFilter(false);
                  }, 300);
                }}
              />
            </div>
          ) : null}
          {props.filteredPeople && (
            <div className="pt-4">
              <PaginationBar
                disabled={props.showTechnologyBlock}
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                    setSelectedPersons([]);
                    setChecked(false);
                    setIndeterminate(false);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <NoResult
          hasFilters={
            props.savedFilters.hasOwnProperty('hasFilters') ? true : false
          }
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters, appuserData, technologySearch} = state;
  const {totalcount, pages, data} = state.filteredPeople;
  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock: technologySearch.showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredPeople: data,
    appuserData,
    processingSideFilterToggle: state.togglingFilters,
  };
}

export default connect(mapStateToProps, {
  filterPeople,
  saveFilter,
  fetchAppuser,
  revealContact,
  revealAllContact,
  peopleSearchEmpty,
  cancelApiRequest,
})(PeopleTable);
