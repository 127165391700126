import {useEffect, useState} from 'react';
import {excludePeopleFilters} from '../../../../constants';
import {connect} from 'react-redux';
import {saveFilter} from '../../../../actions';
import {classNames} from '@commonFunction';

function ExcludePeople(props) {
  const [searchText, setSearchText] = useState('');

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['exclude'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  return (
    <div className="mt-2">
      {excludePeopleFilters.map(({title, type}, index) => {
        const filters = props.savedFilters;
        const filterObject = filters;
        const exclude = filterObject['exclude'];
        const checked = exclude[type];
        return (
          <div
            key={type}
            className={classNames(
              'group relative flex items-start py-1',
              props.disabled
                ? 'pointer-events-none opacity-50'
                : 'pointer-events-auto opacity-100',
            )}>
            <div className="mr-2 flex h-6 items-center">
              <input
                id={`excludepeople-${type}`}
                name={`excludepeople-${type}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:primary"
                onChange={e => {
                  let filters = props.savedFilters;
                  let filterObject = filters;
                  let exclude = filterObject['exclude'];
                  exclude[type] = e.target.checked;

                  updateFilterReducer(exclude);
                }}
                checked={checked}
              />
            </div>
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label
                htmlFor={`excludepeople-${type}`}
                className="select-none text-xs text-gray-900 group-hover:text-primary">
                {title}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
  };
}

export default connect(mapStateToProps, {saveFilter})(ExcludePeople);
